<template>
  <div class="advantage-section">
    <div class="top-section">
      <span>今日图书优势</span>
    </div>
    <div class="bottom-section">
      <div v-for="item in advantageList" :key="item.summary" class="advantage-item">
        <img :src="item.img" alt="">
        <span class="desc">{{item.summary}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    advantageList: {
      type: Array,
      default () {
        return [];
      }
    }
  }
}
</script>

<style scoped lang="scss">
.advantage-section {
  background-color: #fff;
  padding: 0 18%;
  padding-bottom: 40px;

  .top-section {
    padding: 50px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
  }

  .bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .advantage-item {
      width: 230px;
      height: 260px;
      background: #f9f9f9;
      // padding: 0 25px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;

      img {
        width: 70px;
        height: 70px;
        margin-top: 50px;
      }

      .desc {
        margin: 0 25px;
        margin-top: 30px;
      }
    }
  }
}
</style>