<template>
  <div id="app">
    <Home></Home>
  </div>
</template>

<script>
// PC端今日图书官网 版本号:1.0.0120302
import Home from './pages/home/home.vue'
import JsonObj from '../static/json'
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = JsonObj.baiduSrc;
  // console.log('商桥地址:' + JsonObj.baiduSrc);
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style lang="scss" scoped>
</style>
