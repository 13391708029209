<template>
  <div></div>
  <!-- <div class="data-section">
    <div class="top-section">
      <span class="title">企业实力数据</span>
    </div>
    <div class="bottom-section">
      <div class="data-item">
        <span class="count">{{dataObj.libraryNum}}</span>
        <span class="desc">图书馆数量</span>
      </div>
      <div class="line"></div>
      <div class="data-item">
        <span class="count">{{dataObj.readerNum}}</span>
        <span class="desc">服务读者</span>
      </div>
      <div class="line"></div>
      <div class="data-item">
        <span class="count">{{dataObj.bookNum}}</span>
        <span class="desc">藏书数量</span>
      </div>
      <div class="line"></div>
      <div class="data-item">
        <span class="count">{{dataObj.borrowNum}}</span>
        <span class="desc">借阅人次</span>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  props: {
    dataObj: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.data-section {
  height: 245px;
  background-color: #fff;
  background-image: url('../../assets/png/data_bg.png');

  .top-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 0 30px 0;

    .title {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .bottom-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .data-item {
      width: 300px;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .count {
        font-size: 30px;
      }

      .desc {
        margin-top: 20px;
        color: #8d8d8d;
      }
    }

    .line {
      height: 80px;
      width: 1px;
      background-color: #f0f0f0;
    }
  }
}
</style>