<template>
  <div class="home-container">
    <Header id="headerView" :contactObj="JsonObj.contactObj" :tabIndex="menuIndex" @menuTap="menuTapHandler"></Header>
    <TypeIntroduce @crouselItemTap="crouselItemTapHandler" @consultTap="consultTapHandler" :bannerList="JsonObj.bannerList" :typeList="JsonObj.typeList"></TypeIntroduce>
    <DataIntroduce :dataObj="JsonObj.dataObj"></DataIntroduce>
    <SceneIntroduce :sceneList="JsonObj.sceneList" id="sceneView"></SceneIntroduce>
    <ProductIntroduce :productList="JsonObj.productList"></ProductIntroduce>
    <AdvantageIntroduce :advantageList="JsonObj.advantageList" id="advantageView"></AdvantageIntroduce>
    <CatalogIntroduce :operateList="JsonObj.operateList"></CatalogIntroduce>
    <FunctionIntroduce :functionList="JsonObj.functionList" id="functionView"></FunctionIntroduce>
    <PartnerIntroduce :partnerList="JsonObj.partnerList" id="partnerView"></PartnerIntroduce>
    <NewsIntroduce @newsCellTap="newsCellTapHandler" :newsList="JsonObj.newsList" id="newsView"></NewsIntroduce>
    <AboutIntroduce :aboutObj="JsonObj.aboutObj" id="aboutView"></AboutIntroduce>
    <Footer :companyObj="JsonObj.companyObj"></Footer>
    <ToolBar @toolbarTap="toolbarTapHandler"></ToolBar>
    <el-dialog
    title="联系客服"
    :visible.sync="serviceShow"
    width="25%"
    :before-close="handleClose">
    <div class="service-dialog-section">
      <img class="service-dialog-img" src="../../assets/png/qrcode/qrcode_contact.jpg" alt="">
      <p class="service-dialog-title">请用微信扫一扫上方二维码添加客服微信</p>
    </div>
  </el-dialog>
    <el-dialog
      title="加盟今日图书城市合伙人"
      :visible.sync="agentShow"
      width="30%"
      :before-close="handleClose">
      <el-form ref="form" :model="agentInfo" label-width="80px">
        <el-form-item label="联系人:">
          <el-input placeholder="请输入" style="width: 90%;" v-model="agentInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="联系方式:">
          <el-input placeholder="请输入" style="width: 90%;" v-model="agentInfo.phone"></el-input>
        </el-form-item>
        <el-form-item label="所在城市:">
          <el-input placeholder="请输入" style="width: 90%;" v-model="agentInfo.city"></el-input>
        </el-form-item>
        <el-form-item label="申请理由:">
          <el-input type="textarea" placeholder="请输入" style="width: 90%;" v-model="agentInfo.reason"></el-input>
        </el-form-item>
      </el-form>
      <div style="padding: 0 20px;" class="explain-section">
        <span class="explain">说明：<br/>1、目前加盟今日图书城市合伙人不需要任何加盟费<br/>2、凡是通过今日图书官方认证的城市合伙人，颁发城市合伙人证书<br/>3、成为今日图书城市合伙人后，将会获得今日图书销售的超高提成<br/>4、城市合伙人须认可今日图书产品，并且有丰富的客户资源</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="agentShow = false">取 消</el-button>
        <el-button style="background-color: #f98800; border: none;" type="primary" @click="submitAgentHandler">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="申请试用"
      :visible.sync="tryShow"
      width="30%"
      :before-close="handleClose">
      <el-form ref="form" :model="tryInfo" label-width="100px">
        <el-form-item label="单位名称:">
          <el-input placeholder="请输入" style="width: 90%;" v-model="tryInfo.company"></el-input>
        </el-form-item>
        <el-form-item label="联系人:">
          <el-input placeholder="请输入" style="width: 90%;" v-model="tryInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input placeholder="请输入" style="width: 90%;" v-model="tryInfo.phone"></el-input>
        </el-form-item>
        <el-form-item label="图书数量(本):">
          <el-input placeholder="请输入" style="width: 90%;" v-model="tryInfo.bookNum"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tryShow = false">取 消</el-button>
        <el-button style="background-color: #f98800; border: none;" type="primary" @click="submitTryHandler">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="申请方案"
      :visible.sync="planShow"
      width="30%"
      :before-close="handleClose">
      <el-form ref="form" :model="planInfo" label-width="100px">
        <el-form-item label="联系人:">
          <el-input placeholder="请输入" style="width: 90%;" v-model="planInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input placeholder="请输入" style="width: 90%;" v-model="planInfo.phone"></el-input>
        </el-form-item>
        <el-form-item label="行业:">
        <el-select style="width: 90%;" v-model="planInfo.industry" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="需求说明:">
          <el-input type="textarea" placeholder="请输入" style="width: 90%;" v-model="planInfo.demand"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="planShow = false">取 消</el-button>
        <el-button style="background-color: #f98800; border: none;" type="primary" @click="submitPlanHandler">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Header from '../../components/header/header.vue'
import Footer from '../../components/footer/footer.vue'
import TypeIntroduce from '../../components/typeIntroduce/typeIntroduce.vue'
import DataIntroduce from '../../components/dataIntroduce/dataIntroduce.vue'
import SceneIntroduce from '../../components/sceneIntroduce/sceneIntroduce.vue'
import ProductIntroduce from '../../components/productIntroduce/productIntroduce.vue'
import AdvantageIntroduce from '../../components/advantageIntroduce/advantageIntroduce.vue'
import CatalogIntroduce from '../../components/catalogIntroduce/catalogIntroduce.vue'
import FunctionIntroduce from '../../components/functionIntroduce/functionIntroduce.vue'
import PartnerIntroduce from '../../components/partnerIntroduce/partnerIntroduce.vue'
import NewsIntroduce from '../../components/newsIntroduce/newsIntroduce.vue'
import AboutIntroduce from '../../components/aboutIntroduce/aboutIntroduce.vue'
import JsonObj from '../../../static/json'
import ToolBar from '../../components/toolbar/toolbar.vue'
import {uploadAgentInfo, uploadTryInfo, uploadPlanInfo} from '../../api/api'

export default {
  components: {
    Header,
    Footer,
    TypeIntroduce,
    DataIntroduce,
    SceneIntroduce,
    ProductIntroduce,
    AdvantageIntroduce,
    CatalogIntroduce,
    FunctionIntroduce,
    PartnerIntroduce,
    NewsIntroduce,
    AboutIntroduce,
    ToolBar
  },
  data () {
    return {
      menuIndex: 0,
      JsonObj: JsonObj,
      agentShow: false,
      tryShow: false,
      planShow: false,
      serviceShow: false,
      agentInfo: {
        name: '',
        phone: '',
        city: '',
        reason: ''
      },
      tryInfo: {
        name: '',
        phone: '',
        bookNum: '',
        company: ''
      },
      planInfo: {
        name: '',
        phone: '',
        industry: '',
        demand: ''
      },
      options: [{
        value: '职工书屋',
        label: '职工书屋'
      }, {
        value: '农家书屋',
        label: '农家书屋'
      }, {
        value: '总分馆',
        label: '总分馆'
      }, {
        value: '学校',
        label: '学校'
      }, {
        value: '部队',
        label: '部队'
      }, {
        value: '科研院所',
        label: '科研院所'
      }, {
        value: '绘本馆',
        label: '绘本馆'
      }, {
        value: '书店',
        label: '书店'
      }, {
        value: '其他',
        label: '其他'
      }]
    }
  },
  methods: {
    crouselItemTapHandler (item) {
      if (item.url) {
        window.open(item.url, '__blank');
      }
    },
    newsCellTapHandler (news) {
      // console.log('新闻对象:' + JSON.stringify(news));
      window.open(news.url, '__blank');
    },
    /**
     * 代理表单信息提交按钮
     */
    submitAgentHandler () {
      // console.log('代理信息提交:' + JSON.stringify(this.agentInfo));
      if (!this.agentInfo.name) {
        this.$message({
          message: '联系人不能为空',
          type: 'warning'
        });
        return;
      }
      if (!this.agentInfo.phone) {
        this.$message({
          message: '联系方式不能为空',
          type: 'warning'
        });
        return;
      }
      if (!this.agentInfo.city) {
        this.$message({
          message: '所在城市不能为空',
          type: 'warning'
        });
        return;
      }
      if (!this.agentInfo.reason) {
        this.$message({
          message: '申请理由不能为空',
          type: 'warning'
        });
        return;
      }
      let param = {
        contacts: this.agentInfo.name,
        phone: this.agentInfo.phone,
        city: this.agentInfo.city,
        reason: this.agentInfo.reason,
      }
      uploadAgentInfo(param).then(res => {
        // console.log('代理信息表单提交回调:' + JSON.stringify(res));
        if (res.data.errcode === 0) {
          this.agentShow = false;
          setTimeout(() => {
            this.showSuccessModal();
          }, 500);
        } else {
          this.$message({
            message: '提交失败',
            type: 'error'
          });
        }
      })
    },
    showSuccessModal () {
      this.$confirm('感谢关注今日图书，工作人员会尽快跟您联系！', '申请成功', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showCancelButton: false,
        type: 'success'
      });
    },
    handleClose () {
      // console.log('我点击了关闭代理商弹出框');
      this.agentShow = false;
      this.tryShow = false;
      this.planShow = false;
      this.serviceShow = false;
    },
    submitPlanHandler () {
      // console.log('申请方案的表单内容:' + JSON.stringify(this.planInfo));
      if (!this.planInfo.name) {
        this.$message({
          message: '联系人不能为空',
          type: 'warning'
        });
        return;
      }
      if (!this.planInfo.phone) {
        this.$message({
          message: '联系电话不能为空',
          type: 'warning'
        });
        return;
      }
      if (!this.planInfo.industry) {
        this.$message({
          message: '行业不能为空',
          type: 'warning'
        });
        return;
      }
      if (!this.planInfo.demand) {
        this.$message({
          message: '需求说明不能为空',
          type: 'warning'
        });
        return;
      }
      let param = {
        name: this.planInfo.name,
        phone: this.planInfo.phone,
        industry: this.planInfo.industry,
        demand: this.planInfo.demand
      }
      uploadPlanInfo(param).then(res => {
        // console.log('申请方案回调:' + JSON.stringify(res));
        if (res.data.errcode === 0) {
          this.planShow = false;
          setTimeout(() => {
            this.showSuccessModal();
          }, 500);
        } else {
          this.$message({
            message: '提交失败',
            type: 'error'
          });
        }
      })
    },
    /**
     * 咨询
     * 按钮点击事件
     */
    consultTapHandler () {
      // console.log('我点击了咨询按钮');
      document.getElementById('nb_invite_ok').click();
    },
    submitTryHandler () {
      if (!this.tryInfo.name) {
        this.$message({
          message: '联系人不能为空',
          type: 'warning'
        });
        return;
      }
      if (!this.tryInfo.phone) {
        this.$message({
          message: '联系方式不能为空',
          type: 'warning'
        });
        return;
      }
      if (!this.tryInfo.company) {
        this.$message({
          message: '单位不能为空',
          type: 'warning'
        });
        return;
      }
      if (!this.tryInfo.bookNum) {
        this.$message({
          message: '书本数量不能为空',
          type: 'warning'
        });
        return;
      }
      let param = {
        name: this.tryInfo.name,
        phone: this.tryInfo.phone,
        unitName: this.tryInfo.company,
        bookNumbers: this.tryInfo.bookNum,
      }
      uploadTryInfo(param).then(res => {
        // console.log('代理信息表单提交回调:' + JSON.stringify(res));
        if (res.data.errcode === 0) {
          this.tryShow = false;
          setTimeout(() => {
            this.showSuccessModal();
          }, 500);
        } else {
          this.$message({
            message: '提交失败',
            type: 'error'
          });
        }
      })
    },
    toolbarTapHandler (url) {
      // console.log('我点击了:' + url);
      switch (url) {
        case 'contact':
          // document.getElementById('nb_invite_ok').click();
          this.serviceShow = true;
          break;
        case 'agent':
          this.agentShow = true;
          break;
        case 'try':
          this.tryShow = true;
          break;
        case 'plan':
          this.planShow = true;
          break;
        default:
          break;
      }
    },
    /**
     * 顶部菜单栏点击事件
     */
    menuTapHandler (url) {
      // console.log('我点击了url:' + url); 
      const menuIndexObj = {
        home: 0,
        example: 1,
        advantage: 2,
        function: 3,
        partner: 4,
        news: 5,
        about: 6
      }
      this.menuIndex = menuIndexObj[url];
      switch (url) {
        case 'home':
          this.scrollToView('headerView');
          break;
        case 'example':
          this.scrollToView('sceneView');
          break;
        case 'advantage':
          this.scrollToView('advantageView');
          break;
        case 'function':
          this.scrollToView('functionView');
          break;
        case 'partner':
          this.scrollToView('partnerView');
          break;
        case 'news':
          this.scrollToView('newsView');
          break;
        case 'about':
          this.scrollToView('aboutView');
          break;
        default:
          break;
      }
    },
    scrollToView (id) {
      let target = 0;
      let targetbox = {};
      //获取指定的div盒
      targetbox = document.getElementById(id);
      //获取这个div盒的高度位置
      target = targetbox.offsetTop - 80;
      // console.log(this.target)
      //将滚动条定位到这个高度位置
      document.documentElement.scrollTop = target;
    }
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  height: 100%;
  min-width: 1880px;
  padding: 90px 0 340px 0;
}

.service-dialog-section {
  text-align: center;
  .service-dialog-img {
    width: 300px;
    height: 300px;
  }
  .service-dialog-title {
    margin-top: 10px;
    font-size: 1.0rem;
  }
}

</style>