/**
 * Created by wangxin on 2018/6/15.
 */
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue from 'vue'
// import router from '../router'

Vue.use(VueAxios, axios)
// import router from '../router'

const OK = 0
const TOKEN_EXPIRED = 40003

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = '/api'
} else {
  axios.defaults.baseURL = ''
}
// 访问超时配置
axios.defaults.timeout = 40000
axios.defaults.retry = 4
axios.defaults.retryDelay = 1000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// http request拦截器
// TODO 重新设置请求头机制
axios.interceptors.request.use(
  config => {
    let user = JSON.parse(localStorage.getItem('$staff'))
    if (user) {
      config.headers['Access-Token'] = user.token
    }
    // config.headers['Access-Token'] = 'eyJhbGciOiJIUzI1NiJ9.eyJqb2JudW1iZXIiOiIxODA2MTMiLCJleHAiOjE1MzA2ODYwMjN9.Ndn_Yl0eJaUzd13aacwR-Pntihp4vXRYmXFahDwl1F8'
    return config
  },
  error => {
    // return Promise.reject(error)
    return Promise.reject(error)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  response => {
    if (response) {
      switch (response.data.errcode) {
        case OK:
          return response
        case TOKEN_EXPIRED:
          return response
        default:
          return response
      }
    }
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 403:
          break
        case 401:
          console.log('权限不够')
          break
        default:
          return error
      }
    }
    return Promise.reject(error.response.data)
  }
)

axios.interceptors.response.use(undefined, function axiosRetryInterceptor (err) {
  var config = err.config
  // If config does not exist or the retry option is not set, reject
  if (!config || !config.retry) return Promise.reject(err)

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0

  // Check if we've maxed out the total number of retries
  if (config.__retryCount >= config.retry) {
    // Reject with the error
    return Promise.reject(err)
  }

  // Increase the retry count
  config.__retryCount += 1

  // Create new promise to handle exponential backoff
  var backoff = new Promise(function (resolve) {
    setTimeout(function () {
      resolve()
    }, config.retryDelay || 1)
  })

  // Return the promise in which recalls axios to retry the request
  return backoff.then(function () {
    return axios(config)
  })
})

export default axios
