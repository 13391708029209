import axios from '../common/http'
import qs from 'qs'

export function uploadAgentInfo (condition) {
  return axios.post('/staff-backend/email/ibookAgent', qs.stringify(condition))
}

export function uploadTryInfo (condition) {
  return axios.post('/staff-backend/email/customer/send', qs.stringify(condition))
}

export function uploadPlanInfo (condition) {
  return axios.post('/staff-backend/email/plan/apply', qs.stringify(condition))
}