<template>
  <div></div>
  <!-- <div class="scene-section"> -->
    <!-- <div class="top-section">
      <span class="title">我们的产品备受欢迎</span>
    </div>
    <div class="middle-section">
      <div class="navbar-section">
        <span
          @click="navbarItemAction(item.industry)"
          class="navbar-item"
          v-for="item in sceneList"
          :key="item.industry"
          :class="{'selected': selectIndustry === item.industry}"
          >{{ item.industry }}</span
        >
      </div>
    </div>
    <div class="bottom-section">
      <el-carousel :interval="4000" type="card" height="310px">
        <el-carousel-item v-for="item in imgsArr" :key="item.title">
          <img class="carousel-img" :src="item.img" alt="">
          <span class="carousel-title">{{ item.title }}</span>
        </el-carousel-item>
      </el-carousel>
    </div> -->
  <!-- </div> -->
</template>

<script>
export default {
  components: {
  },
  props: {
    sceneList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      selectIndustry: '政府机关',
      imgsArr: []
    }
  },
  created () {
    this.imgsArr = this.sceneList[0].imgs;
  },
  methods: {
    onChange() {},
    navbarItemAction (industry) {
      console.log('我点击的标题:' + industry);
      this.selectIndustry = industry;
      this.sceneList.forEach(item => {
        if (item.industry === industry) {
          this.imgsArr = item.imgs;
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
.scene-section {
  height: 580px;
  background-color: #fff;

  .bottom-section {
    height: 340px;

    .el-carousel {
      margin: 0 150px;
      height: 340px;
      margin-top: 20px;
    }
    
    .el-carousel__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 290px;
    }

    .carousel-img {
      width: 530px;
      height: 260px;
    }

    .carousel-title {
      margin-top: 20px;
      font-size: 17px;
    }
  }

  .top-section {
    padding: 50px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .middle-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .navbar-section {
      width: 720px;
      height: 30px;
      display: flex;

      .navbar-item {
        width: calc(100% / 8);
        text-align: center;
        height: 30px;
        line-height: 30px;
        color: #7d7d7d;
      }

      .selected {
        color: #fff;
        background-color: #fa480d;
      }
    }
  }
}
</style>