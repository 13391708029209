<template>
  <div class="toolbar-container">
    <div @click="toolbarAction(item.url)" class="toolbar-item" v-for="item in toolArr" :key="item.url">
        <img :src="item.img" class="tool-img" alt="">
        <span class="tool-title">{{item.title}}</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      toolArr: [
        {
          title: '联系客服',
          img: require('../../assets/png/tool_wechat.png'),
          url: 'contact'
        }, {
          title: '成为代理商',
          img: require('../../assets/png/tool_agent.png'),
          url: 'agent'
        }, {
          title: '申请试用',
          img: require('../../assets/png/tool_try.png'),
          url: 'try'
        }, {
          title: '申请方案',
          img: require('../../assets/png/tool_plan.png'),
          url: 'plan'
        }
      ]
    }
  },
  methods: {
    toolbarAction (url) {
      this.$emit('toolbarTap', url);
    }
  }
}
</script>

<style scoped lang="scss">
.toolbar-container {
  width: 75px;
  height: 420px;
  background-color: #f98800;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -210px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 999;
  padding: 0 10px;

  .toolbar-item {
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-bottom: 1px #fff solid;

    &:last-child {
      border-bottom: none;
    }

    .tool-img {
      width: 40px;
      height: 40px;
    }

    .tool-title {
      font-size: 15px;
      margin-top: 5px;
    }
  }
}
</style>