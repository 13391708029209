<template>
  <div class="type-section">
    <!-- <div class="img-section">
      <el-carousel :interval="4000" height="500px">
        <el-carousel-item v-for="item in bannerList" :key="item.img">
          <img @click="crouselItemAction(item)" :src="item.img" class="banner-img" alt="">
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <div class="type-content">
      <div class="type-item" v-for="item in typeList" :key="item.summary">
        <img class="type-img" :src="item.img" alt="">
        <span class="type-title">{{item.title}}</span>
        <span class="type-desc">{{item.summary}}</span>
        <!-- <button @click="consultAction" class="type-btn">立即咨询</button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerList: {
      type: Array,
      default () {
        return [];
      }
    },
    typeList: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    consultAction () {
      console.log('我点击了咨询按钮');
      this.$emit('consultTap');
    },
    crouselItemAction (item) {
      console.log('轮播图点击事件:' + JSON.stringify(item));
      this.$emit('crouselItemTap', item);
    } 
  }
}
</script>

<style scoped lang="scss">
.type-section {
  height: 450px;
  background-color: #fff;
  position: relative;

  .el-carousel__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
  }

  .is-active {
    transform: scale(1.0) !important;
  }

  .banner-img {
    width: 1920px;
    height: 500px;
    cursor: pointer;
  }

  .type-content {
    width: 1200px;
    height: 340px;
    position: absolute;
    bottom: 70px;
    left: 50%;
    margin-left: -600px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .type-item {
      box-sizing: border-box;
      border: 1px #f0f0f0 solid;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 230px;
      height: 300px;

      .type-img {
        width: 230px;
        height: 140px;
      }

      .type-title {
        font-size: 17px;
        margin-top: 20px;
      }

      .type-desc {
        font-size: 13px;
        margin: 0 20px;
        margin-top: 20px;
        color: #8e8e8e;
      }

      .type-btn {
        width: 190px;
        height: 30px;
        background-color: #f98b0f;
        color: #fff;
        margin-top: 20px;
        border: none;
        outline: none;
      }
    }
  }
}
</style>