module.exports = {
    contactObj: {
        wechat: 'jrtsxs888',
        phone: '400-851-3851'
    },
    bannerList: [{
        img: require('../src/assets/png/banner/banner_img02.png'),
        url: 'http://jinritushu.com/zgsw.mp4'
    }
    // , {
    //     img: require('../src/assets/png/banner/banner_img01.png'),
    //     url: 'https://mp.weixin.qq.com/s/1b_3teloQMr3Zhr0cuq9zg'
    // }
    ],
    typeList: [{
        img: require('../src/assets/png/type/type_img01.png'),
        title: '职工书屋',
        summary: '适合各类企事业单位职工书屋的图书管理系统，有效提升职工书屋服务体验'
    }, {
        img: require('../src/assets/png/type/type_img02.png'),
        title: '农家书屋',
        summary: '手机上找书、借书、还书，还可以刷脸、刷指纹，乡镇居民借书看书更方便'
    }, {
        img: require('../src/assets/png/type/type_img03.png'),
        title: '全省县级总分馆',
        summary: '采用云平台部署架构，由总平台进行统一配置，各分馆图书图书相互流通'
    }, {
        img: require('../src/assets/png/type/type_img04.png'),
        title: '部队科研院所',
        summary: '今日图书单机版可在无网络环境下安装使用，具有可靠性高、保密性强等优势'
    }, {
        img: require('../src/assets/png/type/type_img05.png'),
        title: '书店绘本馆',
        summary: '支持设置押金、租金、会员费、支持线上或者线下支付，读者自助注册，扫码借书'
    }],
    dataObj: {
        libraryNum: '50,123',
        readerNum: '8,058,514',
        bookNum: '150,962,851',
        borrowNum: '65,070,091'
    },
    sceneList: [{
        industry: '政府机关',
        imgs: [{
            img: require('../src/assets/png/government/government_qinghai.png'),
            title: '青海省人民政府办公厅直属机关工会委员会'
        }, {
            img: require('../src/assets/png/government/government_zhongyang.png'),
            title: '中共中央办公厅秘书局'
        }, {
            img: require('../src/assets/png/government/government_quanzong.png'),
            title: '中华全国总工会'
        }]
    }, {
        industry: '金融行业',
        imgs: [{
            img: require('../src/assets/png/financial/financial_nongye.png'),
            title: '中国农业银行'
        }, {
            img: require('../src/assets/png/financial/financial_renming.png'),
            title: '中国人民银行'
        }, {
            img: require('../src/assets/png/financial/financial_chuxu.png'),
            title: '中国邮政储蓄银行'
        }]
    }, {
        industry: '绘本馆',
        imgs: [{
            img: require('../src/assets/png/cartoon/cartoon_bengda.png'),
            title: '蹦跶书舍'
        }, {
            img: require('../src/assets/png/cartoon/cartoon_haibei.png'),
            title: '嗨呗英语'
        }, {
            img: require('../src/assets/png/cartoon/cartoon_yiya.png'),
            title: '咿呀英文绘本馆'
        }]
    }, {
        industry: '地产行业',
        imgs: [{
            img: require('../src/assets/png/land/land_baoli.png'),
            title: '保利集团'
        }, {
            img: require('../src/assets/png/land/land_wanke.png'),
            title: '万科集团'
        }, {
            img: require('../src/assets/png/land/land_yuanyang.png'),
            title: '远洋地产'
        }]
    }, {
        industry: '医疗行业',
        imgs: [{
            img: require('../src/assets/png/medical/medical_mingxing.png'),
            title: '闵行区中西医结合医院'
        }, {
            img: require('../src/assets/png/medical/medical_diwu.png'),
            title: '上海市第五人民医院'
        }, {
            img: require('../src/assets/png/medical/medical_zhoushan.png'),
            title: '舟山医院'
        }]
    }, {
        industry: '公共图书馆',
        imgs: [{
            img: require('../src/assets/png/public/public_hainan.png'),
            title: '海南省图书馆'
        }, {
            img: require('../src/assets/png/public/public_hangzhou.png'),
            title: '杭州童悦馆'
        }, {
            img: require('../src/assets/png/public/public_wuan.png'),
            title: '武安市图书馆'
        }]
    }, {
        industry: '制造业',
        imgs: [{
            img: require('../src/assets/png/manufacturing/manufacturing_jinhai.png'),
            title: '金海重工股份有限公司'
        }, {
            img: require('../src/assets/png/manufacturing/manufacturing_xugong.png'),
            title: '徐工集团徐州重型机械有限公司'
        }, {
            img: require('../src/assets/png/manufacturing/manufacturing_zhoushan.png'),
            title: '舟山中远海运重工'
        }]
    }, {
        industry: '教育科研',
        imgs: [{
            img: require('../src/assets/png/research/research_youkeyuan.png'),
            title: '国网河北电科院'
        }, {
            img: require('../src/assets/png/research/research_yingchuan.png'),
            title: '银川市实验中学'
        }, {
            img: require('../src/assets/png/research/research_eryuan.png'),
            title: '中国航天二院'
        }]
    }],
    productList: [{
        title: '图书管理系统',
        summary: '同时支持手机电脑管书，支持读者自助借还书，支持指纹借书，支持刷脸借书，具有图书识别率高、图书智能编目分类、借阅参数灵活设置、可嵌入公众号等优势。',
        img: require('../src/assets/png/product/product_img01.png')
    }, {
        title: '自动编目移动终端',
        summary: '具有多项技术专利的革命性产品，极大降低图书编目、分类、上架的专业门槛，有效提升图书管理效率，是图书管理员的好帮手。',
        img: require('../src/assets/png/product/product_img02.png')
    }, {
        title: 'RFID图书管理解决方案',
        summary: '利用RFID技术有效解决图书丢失、被盗等问题。和今日图书高度结合，不需要额外的消磁、写防盗位操作，读者手机借书后便可直接拿书离开。',
        img: require('../src/assets/png/product/product_img03.png')
    }],
    advantageList: [{
        img: require('../src/assets/png/advantage/advantage_icon01.png'),
        summary: '基于微信小程序开箱即用，无需下载安装，无需办卡领证'
    }, {
        img: require('../src/assets/png/advantage/advantage_icon02.png'),
        summary: '微信扫码一键借还，读者间可以相互转借'
    }, {
        img: require('../src/assets/png/advantage/advantage_icon03.png'),
        summary: '图书信息自动识别，中文图书识别率较高'
    }, {
        img: require('../src/assets/png/advantage/advantage_icon04.png'),
        summary: '押金、租金、借阅天数、借阅数量均可后台配置'
    }, {
        img: require('../src/assets/png/advantage/advantage_icon05.png'),
        summary: '支持嵌入公众号，将图书馆放在公众号上，简直是吸粉工具'
    }, {
        img: require('../src/assets/png/advantage/advantage_icon06.png'),
        summary: '对于借阅图书即将逾期的读者，系统自动发送逾期提醒'
    }, {
        img: require('../src/assets/png/advantage/advantage_icon07.png'),
        summary: '支持指纹借书，没有手机的读者照样可以借书'
    }, {
        img: require('../src/assets/png/advantage/advantage_icon08.png'),
        summary: '自动生成和打印索书号标签，图书馆上架查找更简单'
    }, {
        img: require('../src/assets/png/advantage/advantage_icon09.png'),
        summary: '支持同时管理多个书屋，书屋之间可以配置通借通还'
    }, {
        img: require('../src/assets/png/advantage/advantage_icon10.png'),
        summary: '二维码标题客户可以自己定义，便于客户宣传推广'
    }],
    operateList: [{
        img: require('../src/assets/png/process/process_img01.png'),
        title: '智能编目'
    }, {
        img: require('../src/assets/png/process/process_img02.png'),
        title: '图书上架'
    }, {
        img: require('../src/assets/png/process/process_img03.png'),
        title: '手机借书'
    }, {
        img: require('../src/assets/png/process/process_img04.png'),
        title: '手机还书'
    }, {
        img: require('../src/assets/png/process/process_img05.png'),
        title: '借阅记录'
    }],
    functionList: [{
        title: '01、书刊编目',
        summary: '自动识别书刊信息，书刊智能分类，同步生成索书号标签'
    }, {
        title: '02、书刊录入',
        summary: '支持手机和电脑录入书刊，可以多人同时录入，一秒一本'
    }, {
        title: '03、书刊查询',
        summary: '可以再手机微信上，根据书名或者作者查询书刊'
    }, {
        title: '04、书刊预约',
        summary: '书刊预约后，24小时内只有预约的读者可以进行借阅'
    }, {
        title: '05、书刊借阅',
        summary: '用微信扫描贴在书上的二维码，自动获取书刊信息，进行借阅'
    }, {
        title: '06、书刊归还',
        summary: '管理员扫描贴在书上的二维码，自动获取借阅信息，进行还书'
    }, {
        title: '07、书刊挂失',
        summary: '当图书丢失后，读者可以直接在手机微信上挂失图书'
    }, {
        title: '08、书刊置顶',
        summary: '重点推荐的图书，可将书刊置顶，置顶后书刊显示在最前面'
    }, {
        title: '09、书刊下架',
        summary: '书刊不再需要管理时，将书刊下架，书刊将不会被查询和借阅'
    }, {
        title: '10、书刊盘点',
        summary: '盘点完成后，系统会自动统计出在库、外借以及丢失的书刊'
    }, {
        title: '11、书刊捐赠',
        summary: '开启捐赠功能后，读者可以通过扫码方式将书共享给图书馆'
    }, {
        title: '12、书刊售卖',
        summary: '将书刊设置为可卖状态，可直接扫码购买书刊或者借转买'
    }, {
        title: '13、通借通还',
        summary: '同一账号的图书馆可设置通借通还，在任意图书馆借书还书'
    }, {
        title: '14、指纹借书',
        summary: '对于未使用手机的读者，可以采用刷指纹的方式借阅书刊'
    }, {
        title: '15、自助还书',
        summary: '借阅书刊的读者可以自行进行扫码还书，无需管理员参与'
    }, {
        title: '16、借阅记录',
        summary: '所有借阅行为都会详细记录、包括借阅时间、读者、书名等'
    }, {
        title: '17、数据报表',
        summary: '包括读者、书刊、借阅记录以及挂失下架等数据报表'
    }, {
        title: '18、读者管理',
        summary: '可以查看读者详细信息，可将不需要借阅的读者移出图书馆'
    }, {
        title: '19、注册审核',
        summary: '对于注册的读者可以进行审核，只有审核通过才能借阅书刊'
    }, {
        title: '20、发布公告',
        summary: '在微信图书馆主页发布公告，将重要事情通知图书馆读者'
    }, {
        title: '21、发布活动',
        summary: '在微信图书馆主页发布活动介绍，并能够上传活动海报'
    }, {
        title: '22、读者荐书',
        summary: '读者可以给图书馆推荐想看的书刊，管理员可以查看荐书记录'
    }, {
        title: '23、分级管理',
        summary: '对于多个图书馆的情形，可以按照省市区或者总分馆模式管理'
    }, {
        title: '24、在线支付',
        summary: '当管理员配置需要押金租金后，读者可以直接线上微信支付'
    }, {
        title: '25、刷脸借书',
        summary: '对于未使用手机的读者，可以采用人脸识别的方式借阅书刊'
    }],
    partnerList: [{
        img: require('../src/assets/png/partner/partner_icon1.png'),
        title: '客户1'
    }, {
        img: require('../src/assets/png/partner/partner_icon2.png'),
        title: '客户2'
    }, {
        img: require('../src/assets/png/partner/partner_icon3.png'),
        title: '客户3'
    }, {
        img: require('../src/assets/png/partner/partner_icon4.png'),
        title: '客户4'
    }, {
        img: require('../src/assets/png/partner/partner_icon5.png'),
        title: '客户5'
    }, {
        img: require('../src/assets/png/partner/partner_icon6.png'),
        title: '客户6'
    }, {
        img: require('../src/assets/png/partner/partner_icon7.png'),
        title: '客户7'
    }, {
        img: require('../src/assets/png/partner/partner_icon8.png'),
        title: '客户8'
    }, {
        img: require('../src/assets/png/partner/partner_icon9.png'),
        title: '客户9'
    }, {
        img: require('../src/assets/png/partner/partner_icon10.png'),
        title: '客户10'
    }, {
        img: require('../src/assets/png/partner/partner_icon11.png'),
        title: '客户11'
    }, {
        img: require('../src/assets/png/partner/partner_icon12.png'),
        title: '客户12'
    }, {
        img: require('../src/assets/png/partner/partner_icon13.png'),
        title: '客户13'
    }, {
        img: require('../src/assets/png/partner/partner_icon14.png'),
        title: '客户14'
    }, {
        img: require('../src/assets/png/partner/partner_icon15.png'),
        title: '客户15'
    }, {
        img: require('../src/assets/png/partner/partner_icon16.png'),
        title: '客户16'
    }, {
        img: require('../src/assets/png/partner/partner_icon17.png'),
        title: '客户17'
    }, {
        img: require('../src/assets/png/partner/partner_icon18.png'),
        title: '客户18'
    }, {
        img: require('../src/assets/png/partner/partner_icon19.png'),
        title: '客户19'
    }, {
        img: require('../src/assets/png/partner/partner_icon20.png'),
        title: '客户20'
    }, {
        img: require('../src/assets/png/partner/partner_icon21.png'),
        title: '客户21'
    }, {
        img: require('../src/assets/png/partner/partner_icon22.png'),
        title: '客户22'
    }, {
        img: require('../src/assets/png/partner/partner_icon23.png'),
        title: '客户23'
    }, {
        img: require('../src/assets/png/partner/partner_icon24.png'),
        title: '客户24'
    }],
    newsList: [{
        title: '图书管理有烦恼？职工书屋专属管理系统来啦！',
        summary: '为建好、管好、用好职工书屋，方便全国各地职工书屋管理员使用且服务好广大职工朋友，全国工会职工书屋基于成熟的云架构和互联网技术，研发建立此款轻量级的专属图书管理系统，重构了图书流转业务和管理模式。',
        date: '10-21',
        year: '2020',
        img: 'https://share-library.oss-cn-beijing.aliyuncs.com/ibook/abvoys0man.jpg',
        url: 'https://mp.weixin.qq.com/s/rq142a41pF4js80UPGDRUA'
    }, {
        title: '武安市“互联网+”全新技术平台引领全市图书馆总分馆建设',
        summary: '在新技术，新模式的互联网+技术平台服务构架下，武安市图书馆为广大群众带来全新的阅读体验，颠覆传统图书馆借阅服务模式，迎来了全新的移动化“互联网+”时代。',
        date: '5-13',
        year: '2020',
        img: 'https://static.lieyunwang.com/upload2/file/201901/142050mkvz2p.png',
        url: 'http://www.wuan.gov.cn/bddt/202005/t20200513_1268829.html'
    }, {
        title: '今日图书发布下一代图书管理系统，纸质图书管理迎来全面移动化',
        summary: '今日，一年一度的“北京图书订货会”在京举办。作为图书管理综合服务商，今日图书参加了本次展会，并在展会上发布了下一代图书管理系统。',
        date: '1-10',
        year: '2019',
        img: 'https://static.lieyunwang.com/upload2/file/201901/142050mkvz2p.png',
        url: 'https://www.sohu.com/a/288085572_118792'
    }, {
        title: '运营一年上架图书100万册，今日图书欲以图书管理为核心打造轻量级的互联网图书服务平台',
        summary: '一台手机、一个小程序，随时随地查找、翻阅周围的社区图书馆、书吧、书屋.....扫码即可自行借书，这样的新式阅读方式你会pick吗？',
        date: '8-20',
        year: '2018',
        img: 'https://static.lieyunwang.com/upload2/file/201808/171817nqiyyf.jpg',
        url: 'https://www.lieyunwang.com/archives/446430'
    }],
    aboutObj: {
        introduce: '云海宝光（北京）文化传媒有限公司（今日图书）是一家具备自主研发能力和产品交付能力的综合性IT公司，拥有数十项计算机软件著作权和多项硬件专利技术，能够为用户和合作伙伴提供完善、完整的产品解决方案。公司成立于2015年，注册资金1000万，公司已荣获国家高新技术企业、双软企业认证、中关村高新技术企业、ISO9001质量管理体系认证、出版物销售许可等荣誉和资质。',
        position: '今日图书是全国总工会职工书屋技术支撑和运行维护单位，是全国工会职工书屋信息普查系统（2019年度）、全国工会职工书屋申报系统（2020年度）和职工书屋管理系统等重要系统的软件研发单位。',
        target: '创造客户价值使我们一直追求的企业目标，今日图书致力于成为客户可信赖的合作伙伴，以开放务实的态度，期待与您更多的合作。',
        establishedTime: '2015',
        patentNum: 15,
        softNum: 49,
        certificateList: [{
            img: require('../src/assets/png/about/about_img01.png'),
            title: '国家高新技术企业证书'
        }, {
            img: require('../src/assets/png/about/about_img02.png'),
            title: '中关村高新技术企业证书'
        }, {
            img: require('../src/assets/png/about/about_img03.png'),
            title: '软件企业证书'
        }, {
            img: require('../src/assets/png/about/about_img04.png'),
            title: '出版物经营许可证'
        }, {
            img: require('../src/assets/png/about/about_img05.png'),
            title: 'ISO9001证书'
        }, {
            img: require('../src/assets/png/about/about_img06.png'),
            title: '编目终端3C证书'
        }, {
            img: require('../src/assets/png/about/about_img07.png'),
            title: '一体机3C证书'
        }]
    },
    companyObj: {
        name: '云海宝光（北京）文化传媒有限公司',
        email: 'kechang@ibook.club',
        phone: '400-851-3851',
        centralAddress: '北京市海淀区花园东路10号高德大厦3层',
        researchAddress: '湖北省武汉市洪山区光谷创业街9栋2楼',
        record: '@2020 云海宝光（北京）文化传媒有限公司',
        icp: '京ICP备18057207号-1',
        wechatQrcode: require('../src/assets/png/qrcode/qrcode_wechat.png'),
        minaQrcode: require('../src/assets/png/qrcode/qrcode_mina.png'),
        contactQrcode: require('../src/assets/png/qrcode/qrcode_contact.png')
    },
    baiduSrc: 'https://hm.baidu.com/hm.js?6c6388955c8707242168d9aff97764af'
}