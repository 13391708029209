<template>
  <div class="news-section">
    <div class="top-section">
      <span class="title">新闻资讯</span>
    </div>
    <div class="bottom-section">
      <div class="left-section">
        <div @click="newsCellAction(item)" v-for="(item, index) in newsList" :key="index" class="news-item">
          <div class="news-left">
            <span class="news-title">{{item.title}}</span>
            <span class="news-desc">{{item.summary}}</span>
          </div>
          <div class="news-right">
            <span class="news-date">{{item.date}}</span>
            <span class="news-year">{{item.year}}</span>
          </div>
        </div>
      </div>
      <div @click="newsCellAction(newsList[0])" class="right-section">
        <img class="news-img" :src="newsList[0].img" alt="">
        <span class="news-title">{{newsList[0].title}}</span>
        <span class="news-desc">{{newsList[0].summary}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newsList: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    newsCellAction (item) {
      this.$emit('newsCellTap', item);
    }
  }
}
</script>

<style scoped lang="scss">
.news-section {
  background-color: #fff;
  padding: 0 18%;

  .top-section {
    padding: 50px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .bottom-section {
    display: flex;
    padding-bottom: 20px;

    .left-section {
      width: 50%;

      .news-item {
        display: flex;
        padding: 20px 0;
        border-bottom: 1px #f0f0f0 solid;

        &:first-child {
          margin-top: -20px;
        }

        .news-left {
          display: flex;
          flex-direction: column;
          width: 80%;

          .news-title {
            font-size: 15px;
            cursor: pointer;
          }

          .news-desc {
            font-size: 13px;
            margin-top: 10px;
            color: #8f8f8f;
          }
        }

        .news-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 20%;
          font-size: 15px;
          color: #8f8f8f;
          
          .news-date {
            font-size: 20px;
          }

          .news-year {
            font-size: 13px;
            margin-top: 10px;
          }
        }
      }
    }

    .right-section {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      
      .news-img {
        width: 570px;
        height: 280px;
      }

      .news-title {
        font-size: 15px;
        margin-top: 20px;
        cursor: pointer;
      }

      .news-desc {
        font-size: 13px;
        margin-top: 10px;
        color: #8f8f8f;
      }
    }
  }

}
</style>