<template>
  <div class="product-section">
    <div v-for="item in productList" :key="item.title" class="product-item">
      <span class="title">{{item.title}}</span>
      <span class="desc">{{item.summary}}</span>
      <img class="img" :src="item.img" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productList: {
      type: Array,
      default () {
        return [];
      }
    }
  }
}
</script>

<style scoped lang="scss">
.product-section {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
  background-image: url('../../assets/png/product_bg.png');

  .product-item {
    width: 440px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .title {
      font-size: 20px;
      margin-top: 40px;
    }

    .desc {
      font-size: 15px;
      color: #7d7d7d;
      margin: 0 20px;
      margin-top: 20px;
    }

    .img {
      width: auto;
      height: auto;
      margin-top: 40px;
    }
  }
}
</style>