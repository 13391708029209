<template>
  <div class="footer-container">
    <div class="left-section">
      <div class="top-section">
        <span class="title">{{companyObj.name}}</span>
      </div>
      <div class="bottom-section">
        <div class="contact-row">
          <span class="sub-title">Tel.</span>
          <span class="sub-desc phone">{{companyObj.phone}}</span>
        </div>
        <div class="contact-row">
          <span class="sub-title">Address.</span>
          <span class="sub-desc address">总部地址：{{companyObj.centralAddress}}</span>
          <span class="sub-desc address">研发中心：{{companyObj.researchAddress}}</span>
        </div>
        <div class="contact-row">
          <span class="sub-title">E-mail.</span>
          <span class="sub-desc email">{{companyObj.email}}</span>
        </div>
      </div>
      <div class="record-row">
        <span class="record">{{companyObj.record}} <a style="color:grey" target="_blank" href="https://beian.miit.gov.cn">{{companyObj.icp}}</a></span>
      </div>
    </div>
    <div class="right-section">
      <div class="qrcode-item">
        <img class="qrcode-img" :src="companyObj.wechatQrcode" alt="">
        <span class="qrcode-title">官方公众号</span>
      </div>
      <div class="qrcode-item">
        <img class="qrcode-img" :src="companyObj.minaQrcode" alt="">
        <span class="qrcode-title">官方小程序</span>
      </div>
       <div class="qrcode-item">
        <img class="qrcode-img" :src="companyObj.contactQrcode" alt="">
        <span class="qrcode-title">官方客服</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companyObj: {
      type: Object,
      default () {
        return {};
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer-container {
  height: 220px;
  background-color: #fff;
  padding: 60px 18%;
  display: flex;

  .left-section {
    width: 70%;

  .top-section {
    font-size: 20px;
    font-weight: 500;
  }

  .bottom-section {
    display: flex;
    flex-wrap: wrap;

      .contact-row {
        width: 50%;
        font-size: 15px;
        color: #8e8e8e;
        margin-top: 15px;
        display: flex;
        flex-direction: column;

        .sub-desc {
          font-size: 17px;
          margin-top: 10px;
        }

        .phone {
          font-size: 20px;
        }

        .address {
          font-size: 17px;
        }

        .email {
          font-size: 17px;
        }
      }
    }

    .record-row {
      margin-top: 20px;
      font-size: 15px;
      color: #8e8e8e;
    }
  }

  .right-section {
    width: 30%;
    display: flex;
    justify-content: space-between;

    .qrcode-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .qrcode-img {
        width: 120px;
        height: 120px;
      }

      .qrcode-title {
        font-size: 15px;
        margin-top: 10px;
      }
    }
  }
}
</style>