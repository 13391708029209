<template>
  <div class="catalog-section">
    <div class="top-section">
      <span class="title">今日图书管理流程</span>
    </div>
    <div class="bottom-section">
      <div v-for="item in operateList" :key="item.title" class="process-item">
        <img class="process-img" :src="item.img" alt="">
        <span class="process-title">{{item.title}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    operateList: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.catalog-section {
  height: 360px;
  background-image: url('../../assets/png/process_bg.png');

  .top-section {
    padding: 50px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .bottom-section {
    display: flex;
    padding: 0 350px;
    align-items: center;
    justify-content: space-between;

    .process-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .process-img {
        width: 220px;
        height: 190px;
      }

      .process-title {
        font-size: 17px;
        margin-top: 10px;
      }
    }
  }
}
</style>