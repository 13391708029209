<template>
  <div></div>
  <!-- <div class="about-section">
    <div class="left-section">
      <span class="title">关于我们</span>
      <span class="desc">{{aboutObj.introduce}}</span>
       <span class="status">{{aboutObj.position}}</span>
      <span class="duty">{{aboutObj.target}}</span>
    </div>
    <div class="right-section">
      <div class="top-section">
        <div class="info-item">
          <div class="info-left">
            <img src="../../assets/png/about_year_icon.png" alt="">
          </div>
          <div class="info-right">
            <span>{{aboutObj.establishedTime}}<span class="unit">年</span></span>
            <span class="desc">成立时间</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-left">
            <img src="../../assets/png/about_soft_icon.png" alt="">
          </div>
          <div class="info-right">
            <span>{{aboutObj.patentNum}}<span class="unit">个</span></span>
            <span class="desc">专利数量</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-left">
            <img src="../../assets/png/about_patent_icon.png" alt="">
          </div>
          <div class="info-right">
            <span>{{aboutObj.softNum}}<span class="unit">件</span></span>
            <span class="desc">软著数量</span>
          </div>
        </div>
      </div>
      <div class="bottom-section">
        <div v-for="item in aboutObj.certificateList" :key="item.title" class="certificate-item">
          <img class="certificate-img" :src="item.img" alt="">
          <span class="certificate-title">{{item.title}}</span>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  props: {
    aboutObj: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.about-section {
  height: 520px;
  background-color: #FCA65B;
  padding: 0 18%;
  display: flex;
  background-image: url('../../assets/png/about_bg.png');

  .left-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    color: #fff;

    .title {
      margin-top: 70px;
      font-weight: 500;
      font-size: 20px;
    }

    .desc {
      margin-top: 30px;
      margin-right: 30px;
      line-height: 30px;
      font-size: 15px;
    }

    .status {
      margin-top: 30px;
      margin-right: 30px;
      line-height: 30px;
      font-size: 15px;
    }

    .duty {
      margin-top: 30px;
      margin-right: 30px;
      line-height: 30px;
      font-size: 15px;
    }
  }

  .right-section {
    width: 50%;
    padding-left: 60px;

    .bottom-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .certificate-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #fff;
        margin-top: 20px;

        .certificate-title {
          margin-top: 5px;
          font-size: 15px;
        }
      }
    }

    .top-section {
      display: flex;
      padding-top: 70px;

      .info-item {
        display: flex;
        width: 190px;
        height: 70px;

        .info-left {
          width: 40%;
        }

        .info-right {
           width: 60%;
          display: flex;
          flex-direction: column;
          color: #fff;
          font-size: 20px;

          .unit {
            font-size: 15px;
          }

          .desc {
            font-size: 15px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>