<template>
  <div class="header-container">
    <div class="logo-section">
      <img src="../../assets/png/default_logo.png" class="logo-img" alt="">
      <div class="title-section">
        <span class="title">赋能机构阅读</span>
        <span class="sub-title">好用的智能化图书管理系统</span>
      </div>
    </div>
    <div class="menu-section">
      <div class="menu-content">
        <div class="menu-top">
          <div class="contact-item">
            <img class="icon" src="../../assets/png/wechat_icon.png" alt="">
            <span class="contact-title">客服微信：</span>
            <span class="contact-desc">{{contactObj.wechat}}</span>
          </div>
          <div class="contact-item">
            <img class="icon" src="../../assets/png/phone_icon.png" alt="">
            <span class="contact-title">咨询热线：</span>
            <span class="contact-desc">{{contactObj.phone}}</span>
          </div>
        </div>
        <div class="menu-bottom">
          <div @click="menuItemAction(item.url)" class="menu-item" v-for="item in menuArray" :key="item.url">
            <span class="title" :class="{'select-title': item.index === tabIndex}">{{item.title}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabIndex: {
      type: Number,
      default: 0,
    },
    contactObj: {
      type: Object,
      default () {
        return {
          wechat: 'jinritushu',
          phone: '400-851-3851'
        }
      }
    }
  },
  data () {
    return {
      menuArray: [
        {
          title: '首页',
          url: 'home',
          index: 0
        }, 
        // {
        //   title: '案例展示',
        //   url: 'example',
        //   index: 1
        // }, 
        {
          title: '产品优势',
          url: 'advantage',
          index: 2
        }, {
          title: '产品功能',
          url: 'function',
          index: 3
        },
        //  {
        //   title: '合作客户',
        //   url: 'partner',
        //   index: 4
        // },
         {
          title: '新闻资讯',
          url: 'news',
          index: 5
        }, {
          title: '关于我们',
          url: 'about',
          index: 6
        }
      ]
    }
  },
  methods: {
    /**
     * 导航栏点击事件
     */
    menuItemAction (url) {
      console.log('我点击的链接:' + url);
      this.$emit('menuTap', url);
    }
  }
}
</script>

<style scoped lang="scss">
.header-container {
  height: 90px;
  background-color: #fff;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  padding: 0 18%;
  
  .logo-section {
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 292px;

    .logo-img {
      width: 80px;
      height: 80px;
    }

    .title-section {
      display: flex;
      flex-direction: column;
      font-size: 15px;

      .sub-title {
        margin-top: 5px;
      }
    }
  }

  .menu-section {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 482px;

    .menu-content {
      width: 580px;
      height: 100%;

      .menu-top {
        height: 50%;
        display: flex;

        .contact-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          color: #f98800;
          font-size: 17px;

          .icon {
            width: 20px;
            height: 20px;
          }

          .contact-title {
            margin-left: 10px;
            font-size: 16px;
          }

          .contact-desc {
            font-size: 16px;
          }
        }
      }

      .menu-bottom {
        height: 50%;
        display: flex;

        .menu-item {
          width: calc(100% / 7);
          display: flex;
          justify-content: center;
          align-items: center;

          .title {
            font-size: 15px;
            cursor: pointer;
            position: relative;
            transition-duration: .2s;
          }

          .select-title {
            transform: scale(1.1);
          }

          .select-title::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: #f98800;
            top: 103%;
            left: 0;
          }

          .selected-title {
            color: red;
          }
        } 
      }
    }
  }
}
</style>