<template>
  <div class="function-section">
    <div class="top-section">
      <span class="title">产品功能</span>
    </div>
    <div class="bottom-section">
      <div v-for="item in functionList" :key="item.title" class="function-item">
        <span class="index">{{item.title}}</span>
        <!-- <span class="title">{{item.title}}</span> -->
        <span class="desc">{{item.summary}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    functionList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.function-section {
  padding: 0 345px;
  padding-bottom: 40px;

  .top-section {
    padding: 50px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .bottom-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .function-item {
      width: 230px;
      height: 110px;
      background-color: #f8f8f8;
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      .index {
        margin: 0 20px;
        margin-top: 20px;
        font-size: 17px;
        color: #fa713e;
      }

      .title {
        margin: 0 20px;
        margin-top: 10px;
        font-size: 17px;
      }

      .desc {
        margin: 0 20px;
        margin-top: 10px;
        font-size: 13px;
      }
    }

  }
}
</style>