<template>
  <div class="partner-section">
    <!-- <div class="top-section">
      <span class="title">合作客户</span>
    </div>
    <div class="bottom-section">
      <div v-for="item in partnerList" :key="item.title" class="partner-item">
        <img :src="item.img" class="partner-img" alt="">
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    partnerList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.partner-section {
  background-color: #fbfbfb;
  padding: 0 18%;
  padding-bottom: 20px;

  .top-section {
    padding: 50px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .bottom-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .partner-item {
      width: 180px;
      height: 95px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      .partner-img {
        max-width: 120px;
        height: auto;
      }
    }
  }
}
</style>